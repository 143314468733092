import React from 'react';
import { NavBar } from '../Components/NavBar';
import { RenderMdList } from '../Components/mdFunctions';

function Home() {
  return (
    <div className='h-screen box-border bg-zinc-900'>
      <NavBar />
      <div className='flex justify-center'>
        <div className='h-full w-1/2 bg-zinc-800'>
          <RenderMdList />
        </div>
      </div>
    </div>
  );
}

export default Home;