import * as matter from 'gray-matter'
import React, { createElement, useState, useEffect } from 'react'
import remarkGfm from 'remark-gfm'
import Markdown from 'react-markdown'
window.Buffer = window.Buffer || require("buffer").Buffer 

const GetMDFilesPath = () => {
  const [fileList, setFileList] = useState([])

  useEffect(() => {
    const importMarkdownFiles = async () => {
      let markdownFiles = []
      try {
        const context = require.context('../../public/posts/', true, /\.md$/)
        context.keys().forEach((key) => {
          markdownFiles.push(key.slice(2))
        })

        let promises = markdownFiles.map((file) =>
          fetch(`/posts/${file}`)
            .then((response) => response.text())
            .then((fileContent) => ({ name: file, content: fileContent }))
        )

        let fileList = await Promise.all(promises)
        setFileList(fileList) 

      } catch (error) {
        console.error('Error importing Markdown files:', error)
      }
    }

    importMarkdownFiles()
  }, [])

  return fileList
}

export const GetMdObjectArray = () => {
  const markdownFiles = GetMDFilesPath()
  const [mdList, setMdList] = useState([])

  useEffect(() => {
    const parsedMdList = markdownFiles.map(file => {
      let content = file.content
      let parsedContent = matter(content)

      let date = new Date(parsedContent.data.date)
      date.setHours(date.getHours() + 3)
      parsedContent.data.date = date
      parsedContent.fileName = file.name
      return parsedContent
    })
    setMdList(parsedMdList)
  }, [markdownFiles]) 
  
  /* obj struct:
  {
  content
  data { title, author, date, tags}
  fileName
  isEmpty
  }
  */
  return mdList
}

export const RenderMdContent = ({ id }) => {
  const mdContentList = GetMdObjectArray()
  const mdContentObj = mdContentList.find(mdContentObj => mdContentObj.fileName === id + ".md")

  if (!mdContentObj) {
    return null
  }

  const mdContent = mdContentObj.content

  return (
    <div>
      <Markdown className="prose prose-invert whitespace-pre-wrap text-white" remarkPlugins={[remarkGfm]}>
        {mdContent}
      </Markdown>
    </div>
  )
}

export const RenderMdList = () => {
  let mdContentList = GetMdObjectArray()
  mdContentList.sort((a, b) => b.data.date - a.data.date)

  return (
    <div>
      <ul>
        {mdContentList.map((file, index) => (
          <li key={index} className="ml-3">
            <span class="bg-gray-400 inline-block w-2 h-2 bg-black rounded-full"></span>
            <a className="ml-2 text-blue-500 underline" href={"blog/post/" + file.fileName.slice(0, -3) + "/"}>{file.data.title}</a> <span className="text-zinc-400">{" - " + (file.data.date).toLocaleDateString('en-GB').split('/').reverse().join('-')}</span>
          </li>
        ))}
      </ul>
    </div>
  )
  
}