import React from 'react'

export const NavBar = () => {
  return (
    <div className="bg-zinc-950 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <a href="/" className="hover:text-gray-300">Home</a>
          <a href="create" className="hover:text-gray-300">Create</a>
        </div>
      </div>
    </div>
  )
}
