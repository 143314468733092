import { useParams } from 'react-router-dom';
import { RenderMdContent } from '../Components/mdFunctions';
import { NavBar } from '../Components/NavBar';


export const BlogPostPage = () => {
    let { id } = useParams()
    
    return (
        <div className='min-h-screen max-h-full bg-zinc-900'>
            <NavBar />
            <div className='flex justify-center'>
                <div className='min-h-screen max-h-full w-3/5 bg-zinc-800 text-white font-lato p-6'>
                    <RenderMdContent id={id}/>
                </div>
            </div>
        </div>
    )
  }
