import Home from "./Pages/Home"
import { BlogPostPage } from "./Pages/BlogPostPage"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

function App() {
  return (
    <Router>
      <Routes> 
        <Route path="/" element={<Home />} />
        <Route path="/blog/post/:id" element={<BlogPostPage />} />
      </Routes>
    </Router>
  )
}

export default App;

